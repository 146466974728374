/* @font-face {
  font-family: "AlibabaSansJP";
  src: url("./styles/font/AlibabaSansJP-Bold.otf");
  font-weight: 700;
} */

/* @font-face {
  font-family: "AlibabaSansJP";
  src: url("./styles/font/AlibabaSansJP-Black.ttf");
  font-weight: 600;
} */

/* @font-face {
  font-family: "AlibabaSansJP";
  src: url("./styles/font/AlibabaSansJP-Medium.otf");
  font-weight: 500;
} */

/* @font-face {
  font-family: "AlibabaSansJP";
  src: url("./styles/font/AlibabaSansJP-Regular.otf");
  font-weight: 400;
} */

* {
  font-family: "Pretendard";
  font-weight: 500;
  font-size: 15px;
}

@font-face {
  font-family: "Pretendard";
  src: url("./styles/font/Pretendard-Regular.woff");
  font-weight: 400;
}

@font-face {
  font-family: "Pretendard";
  src: url("./styles/font/Pretendard-Medium.woff");
  font-weight: 500;
}

@font-face {
  font-family: "Pretendard";
  src: url("./styles/font/Pretendard-SemiBold.woff");
  font-weight: 600;
}

@font-face {
  font-family: "Pretendard";
  src: url("./styles/font/Pretendard-Bold.woff");
  font-weight: 700;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* body태그의 가상선택자로 이미지 소스만 미리 다운로드 */
body::after {
  /* 소스만 다운받고 화면은 나태내지 않는다. (숨김 처리) */
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;

  /* load images */
  content: url("assets/check-box.svg"); /* 필요한 이미지 소스들 다운 */
}

.wrapper {
  width: 100%;
  height: 100%;
  /* flex: 1; */
  overflow-y: auto;
  z-index: 0;
}
